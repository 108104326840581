<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2">
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="分包单位" prop="supplierId">
                        <el-select v-model="form.supplierId" placeholder="请选择" size="small">
                            <el-option 
                                v-for="item in supplierList" 
                                :key="item.id" 
                                :label="item.name" 
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="罚款金额" prop="money">
                        <el-input type="text" v-model="form.money" size="small" maxlength="15" @blur="fieldTrim(form, 'money')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="罚款日期" prop="date">
                        <el-date-picker
                            v-model="form.date"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            size="small"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input type="textarea" v-model="form.remark" size="small" maxlength="500" @blur="fieldTrim(form, 'remark')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="resetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"

export default {
    name: 'CheckinAdd',
    data(){
        return {
            supplierList: [],
            form: {
                supplierId: '',
                money: '',
                date: '',
                remark: '',
            },
            rules: {
                supplierId: [{required: true, message: '分包单位不能为空', trigger: 'blur'}],
                money: [
                    {required: true, message: '罚款金额不能为空', trigger: 'blur'},
                    {required: true, validator: this.checkMoney, trigger: 'blur'},
                ],
                date: [{required: true, message: '罚款日期不能为空', trigger: 'blur'}],
            },
            submitting: false,
            pickerOptions: {
                disabledDate(time) {
                    let now = new Date();
                    return time.getTime() > now.getTime() 
                        || !(time.getFullYear() == now.getFullYear() && time.getMonth() == now.getMonth());
                }
            },
        }
    },
    created() {
        this.loadSupperList();
    },
    methods: {
        ...common,
        ...verify,
        loadSupperList() {
            http.get("v1/supplier/list").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.supplierList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    let data = {};
                    data.projectId = this.$store.state.org.id;
                    data.supplierId = this.form.supplierId;
                    data.revenue = this.form.money;
                    data.day = this.form.date;
                    data.remark = this.form.remark;

                    http.post('v1/punish/add', data).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('提交成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
    }
};
</script>

<style scoped>
</style>